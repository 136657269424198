import React from "react";


export default function Footer() {
  return (
    <>
      <div
        className="color-white footer d-flex justify-content-center 
       bg-dark w-100 py-2 align-items-center"
      >
        <span>© 2023 Locks R Us LLC </span>
      </div>
    </>
  );
}
