import { useState } from "react";
import { Card, Col, Row, Modal, Button } from "react-bootstrap";

// import Services from "./Services";

export default function ServicesResidential(props) {
  // const [open, setOpen] = useState(false);
  // const [smShow, setSmShow] = useState(false);
  const [lgShow, setLgShow] = useState(false);

  // const Types=[{""}]
  return (
    <>
      <Card
        style={({ width: "100%" }, { border: "none" })}
        className="text-center "
      >
        <Row xs={1} md={2} className="my-5">
          <Col>
            <Card.Img variant="top" src={props.content.img} />
          </Col>
          <Col>
            <Card.Body className="w-md-100">
              <Card.Title className="subheader py-3">
                {props.content.title}
              </Card.Title>
              <Card.Text>{props.content.text}</Card.Text>

              <Button
                onClick={() => setLgShow(true)}
                className="collapse-button"
              >
                {" "}
                Read More
              </Button>

              <Modal
                size="lg"
                show={lgShow}
                onHide={() => setLgShow(false)}
                aria-labelledby="example-modal-sizes-title-lg"
              >
                <Modal.Header closeButton>
                  <Modal.Title id="example-modal-sizes-title-lg">
                    Automotive Services
                  </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <Card.Img variant="top" src={props.content.img} />
                  <div
                    id="example-collapse-text "
                    className="mx-3 mx-md-5  my-4"
                  >
                    At <span className="branding">Pick A Lock LLC</span> , we
                    have the best workmanship when it comes to Residential
                    Locksmith Services. Are you looking for a way to make your{" "}
                    <span className="highlight">residential home safer</span> ?
                    Definitely, we all want to feel secure while at home or away
                    from home! Whenever you want to beef up security for your
                    residential house doors, talk to{" "}
                    <span className="branding">Pick A Lock LLC</span> . We are
                    <span className="highlight">available 24/7</span> , with
                    experienced technical teams readily available to serve you.
                    Our security and locks service is outstanding and
                    unbeatable. We offer the following services:
                    <br /> <br />
                    <ul>
                      <p>
                        <strong>New Locks Installation</strong> <br /> <br />
                        In an event where you are facing a jammed lock or you
                        simply want to change the locks, we are readily
                        available to offer the service. Consider the fact that
                        over time, the lock on your residential house might
                        start having issues as it ages. Or you might want to
                        have the latest lock design on your house doors.
                        Whenever you are looking for a Residential Locksmith
                        Service provider to install new locks, call us and we
                        will be ready to assist you.
                      </p>{" "}
                      <br />
                      <p>
                        <strong>House Lockout</strong> <br />
                        <br />
                        Have you ever experienced house lockout? This might be
                        due to a damaged key that can’t open your door. Or
                        probably, you accidentally misplaced the key to your
                        house. This can be a frustrating incident, and therefore
                        you will need urgent help. Just talk to <span className="branding">Pick A Lock LLC</span> and we will be at your doorstep to rescue
                        you anytime!
                      </p>
                    </ul>
                  </div>
                </Modal.Body>
              </Modal>
            </Card.Body>
          </Col>
        </Row>
      </Card>
    </>
  );
}
