import { Card } from "react-bootstrap";
import React from "react";


export default function Header(props) {
  
   return(
     <>
      <Card className="text-center my-3" style={{ border: "none"}}>
      <Card.Body>
        <Card.Title className="header m-3">{props.title} </Card.Title>
        <Card.Text className="m-3 text-center decoration-slate-300  " >
          {props.desc}
        </Card.Text>
      </Card.Body>
    </Card></>
   )
  
}
