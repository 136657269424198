import "./nav.css";
import React from "react";
import Logo from "../../images/Logo.png"
export default function Navigator() {
  return (
    <>
      <div className="Ad text-center">
        <p>Call Us Now On: +17273870386</p>
      </div>
      <nav>
        <img src={Logo} alt=""width="160px" height="auto"/>
      </nav>
   
    </>
  );
}



