import React from "react";
import ServicesResidential from "./Services-Residential";
import ServicesAutomotive from "./Services-Automotive";
import ServicesCommercial from "./Services-Commercial";

const Array = [
  {
    text: " We provide fast & excellent residential locksmith services that will ensure you feel safe at home.",
    title: "Residential Lockout",
    img: "https://www.terraresidential.com/images/home-banner-1.jpg",
  },
  {
    text: "We carry the best automotive locksmith tools and programming softwares to work on all types of car keys and locks.",
    title: "Automotive",
    img: "https://www.locksmithslocator.com/sites/default/files/car%20lockout_0.jpg",
  },
  {
    text: "We help improve the security of office buildings, commercial establishments, and industrial facilities with our locksmith expertise.",
    title: "Commercial",
    img: "https://www.worldfinance.com/wp-content/uploads/2019/07/B041-Bank-of-the-West.jpg",
  },
];

export default function Services() {
  return (
    <>
      <ServicesResidential content={Array[0]} />
      <ServicesAutomotive content={Array[1]} />
      <ServicesCommercial content={Array[2]} />
    </>
  );
}
