import Navigator from "./components/navigation/Nav";
import Landing from "./components/Landing";
import "bootstrap/dist/css/bootstrap.min.css";
import Services from "./components/services/Services";
import Header from "./components/Header";
import About from "./components/About";
import Process from "./components/Process";
import CallButton from "./components/CallButton";
import Footer from "./components/Footer";
export default function App() {
  const Headers = [
    {
      title: "Services",
      desc: "Some of the services we offer are Emergency Lockouts, Lock Repair & Install, Car Keys, Residential Lockouts, Re-keys & Commercial & Offices Lock Changes.",
    },
    { title: "About Us" },
    { title: "Where To Find Us!" },
    { title: "Your Journey With Us!" },
  ];

  console.log(Headers[0].title);
  return (
    <>
      <Navigator />
      <Landing />
      <Header title={Headers[0].title} desc={Headers[0].desc} />
      <Services />
      <Header title={Headers[3].title} />
      <Process />
      <Header title={Headers[1].title} />
      <About />
      <CallButton />
      <Footer/>

    </>
  );
}
