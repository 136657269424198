import React from 'react';
import {Card,Button} from 'react-bootstrap';
import LandingImg from "../images/landing-new.jpg"

export default function Landing() {
  return (
    <Card className="text-white " style={{border:"none"}}>
      <Card.Img className='border-none landing-img' src={LandingImg} alt="Card image" />
      <Card.ImgOverlay className='text-md-center mx-md-3 mx-lg-5'>

        <div className='text-overlay'>
        <Card.Title className='mt-3 fs-1' >Locks R Us LLC</Card.Title>
        <Card.Text className='mt-3' >
        We offer a variety of locksmith services that are customizable to each individual. We cover Tampa Bay and all surrounding areas. We have technicians everywhere waiting to assist you! We're a local business, open 24/7. We're Licensed and Insured.
        </Card.Text>
        <Card.Text className='d-md-none'>Give us a call on +17273870386 and we'll be right at your doorstep! </Card.Text>
          <Button className='d-none d-md-inline-flex  button-call ' href="tel:+17273870386" >Call Now!</Button>
          </div>
        
      </Card.ImgOverlay>
    </Card>
  );
}

