import { Card, Col, Row } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBell,faPhone,faTruckFast,faThumbsUp } from "@fortawesome/free-solid-svg-icons";

export default function Process() {
  return (
    <Row className="g-5 g-md-3  mx-4 mx-md-3 mx-lg-4 px-2" xs="1" sm="2" md="2" lg="4">
      <Col>
        <Card className=" p-4 text-center h-100 process-card">
          <FontAwesomeIcon icon={faBell} className="icons"/>
          <Card.Body>
            <Card.Title >NEED HELP GETTING IN?</Card.Title>
            <Card.Text>
              Locked outside of your home or your car? No worries!
            </Card.Text>
          </Card.Body>
        </Card>
      </Col>
      <Col>
        <Card className=" p-4 text-center h-100 process-card">
          <FontAwesomeIcon icon={faPhone} className="icons"/>
          <Card.Body>
            <Card.Title>GIVE US A CALL</Card.Title>
            <Card.Text>
              Call us on 
            <br />
             <a href="tel:+17273870386" className="inline-call" > (727)-387-0386 </a> <br /> and we will be there as soon as
              possible.
            </Card.Text>
          </Card.Body>
        </Card>
      </Col>
      <Col>
        <Card className=" p-4 text-center h-100 process-card">
          <FontAwesomeIcon icon={faTruckFast} className="icons" />
          <Card.Body>
            <Card.Title>QUICK SAVE</Card.Title>
            <Card.Text>
              Our locksmith team will take care of the issue quickly &
              efficiently.
            </Card.Text>
          </Card.Body>
        </Card>
      </Col>
      <Col>
        <Card className=" p-4 text-center h-100 process-card">
          <FontAwesomeIcon icon={faThumbsUp} className="icons"/>
          <Card.Body>
            <Card.Title>GOOD TO GO!</Card.Title>
            <Card.Text>
              Get back to whatever you were up to and enjoy the rest of your
              day.
            </Card.Text>
          </Card.Body>
        </Card>
      </Col>
    </Row>
  );
}
