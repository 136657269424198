import { useState } from "react";
import { Card, Col, Row, Modal, Button } from "react-bootstrap";

// import Services from "./Services";

export default function ServicesCommercial(props) {
  // const [open, setOpen] = useState(false);
  // const [smShow, setSmShow] = useState(false);
  const [lgShow, setLgShow] = useState(false);

  // const Types=[{""}]
  return (
    <>
      <Card
        style={({ width: "100%" }, { border: "none" })}
        className="text-center "
      >
        <Row xs={1} md={2} className="my-5">
          <Col>
            <Card.Img variant="top" src={props.content.img} />
          </Col>
          <Col>
            <Card.Body className="w-md-100">
              <Card.Title className="subheader py-3">
                {props.content.title}
              </Card.Title>
              <Card.Text>{props.content.text}</Card.Text>

              <Button
                onClick={() => setLgShow(true)}
                className="collapse-button"
              >
                {" "}
                Read More
              </Button>

              <Modal
                size="lg"
                show={lgShow}
                onHide={() => setLgShow(false)}
                aria-labelledby="example-modal-sizes-title-lg"
              >
                <Modal.Header closeButton>
                  <Modal.Title id="example-modal-sizes-title-lg">
                    Automotive Services
                  </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <Card.Img variant="top" src={props.content.img} />
                  <div
                    id="example-collapse-text "
                    className="mx-3 mx-md-5  my-4"
                  >
                    Are you and your team locked out of your office? Do you
                    require a professional locksmith to handle your office door?
                    At <span className="branding">Pick A Lock LLC</span> , you
                    will get the best professional service when it comes to{" "}
                    <span className="highlight">
                      commercial building door locks
                    </span>{" "}
                    . We also offer emergency services to commercial clients{" "}
                    <span className="highlight">
                      at any hour of the day or night
                    </span>{" "}
                    . Therefore, contact us today for the following services:
                    <br /> <br />
                    <ul>
                      <p>
                        <strong>Business Lockout</strong> <br /> <br />
                        In case you come to your office one morning and you
                        can’t open the door for some reason, all is not lost.
                        You can call us immediately and we will send our team to
                        assist you. There are several reasons why your
                        commercial door might not unlock. These include{" "}
                        <span className="highlight">
                          a lost key, a jammed commercial door lock or even a
                          broken key
                        </span>{" "}
                        . Therefore, whenever you face these challenges, contact
                        us soonest possible to get you back to your office,
                        stat.
                      </p>{" "}
                      <br />
                      <p>
                        <strong>Re-Keying Residential Door Lock</strong> <br />
                        <br />
                        If you just <span className="highlight">lost the key to your office</span> , we will be
                        readily available to make another key for the lock. This
                        is done professionally to make sure the lock is not
                        damaged and also to ensure the new key functions well.
                        Call our customer service representative for a free
                        quote. Alternatively, you can come to our office and we
                        will assist you.
                      </p>
                      <br />
                      <p>
                        <strong>Office safe keying</strong>
                        <br /> <br />
                        Your Office Safe requires professionalism and a high
                        level of trust. Therefore, when hiring a commercial
                        locksmith service, you have got to make sure you hire a
                        professional team. Also, you should hire a technician
                        whom you can trust. We have the best-trusted team at
                        your service for repair or fixing a new lock on your
                        safe. Call us today; we are ready to serve you.
                      </p>
                      <br />
                      {/* <p>
                        <strong>
                          Emergency Devices and Fire Exit Services
                        </strong>{" "}
                        <br /> <br />
                        Whenever you want to fix emergency services devices,
                        call us to assist you. We have trained technicians who
                        can install emergency services devices whenever you need
                        this to be done. We also help in checking and repairing
                        broken fire exit doors.
                      </p> */}
                    </ul>
                  </div>
                </Modal.Body>
              </Modal>
            </Card.Body>
          </Col>
        </Row>
      </Card>
    </>
  );
}
