import { useState } from "react";
import { Card, Col, Row, Modal, Button } from "react-bootstrap";

// import Services from "./Services";

export default function ServicesAutomotive(props) {
  // const [open, setOpen] = useState(false);
  // const [smShow, setSmShow] = useState(false);
  const [lgShow, setLgShow] = useState(false);

  // const Types=[{""}]
  return (
    <>
      <Card
        style={({ width: "100%" }, { border: "none" })}
        className="text-center "
      >
        <Row xs={1} md={2} className="my-5">
          <Col className=" d-md-none">
            <Card.Img variant="top" src={props.content.img} />
          </Col>
          <Col>
            <Card.Body className="w-md-100 mt-md-4 mt-lg-5 pt-lg-5">
              <Card.Title className="subheader py-3">
                {props.content.title}
              </Card.Title>
              <Card.Text>{props.content.text}</Card.Text>

              <Button
                onClick={() => setLgShow(true)}
                className="collapse-button"
              >
                {" "}
                Read More
              </Button>

              <Modal
                size="lg"
                show={lgShow}
                onHide={() => setLgShow(false)}
                aria-labelledby="example-modal-sizes-title-lg"
              >
                <Modal.Header closeButton>
                  <Modal.Title id="example-modal-sizes-title-lg">
                    Automotive Services
                  </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                <Card.Img variant="top" src={props.content.img} />
                  <div
                    id="example-collapse-text "
                    className="mx-3 mx-md-5  my-4"
                  >
                    Are you looking for an Automotive Locksmith near you?
                    Contact <span className="branding"> Pick A Lock LLC </span>
                    for the best services at the best rates. We are the best
                    vehicle locksmith services provider offering exceptional
                    services ’round the clock. That is{" "}
                    <span className="highlight">
                      24 hour car locksmith services
                    </span>{" "}
                    in Florida!
                    <br />
                    <br />
                    We guarantee you the{" "}
                    <span className="highlight">
                      highest level of service within the shortest time possible
                    </span>
                    . Here are some of the services we offer our esteemed
                    customers:
                    <br /> <br />
                    <ul>
                      <p>
                        <strong>Car Key Duplication</strong> <br /> <br />
                        Having an extra car key is probably the best way to be
                        prepared for an emergency. You never know when disaster
                        will strike. Therefore, being very prepared is the best
                        way to handle an emergency. Would you like to have a
                        duplicate car key?Contact{" "}
                        <span className="branding">
                          {" "}
                          Pick A Lock LLC{" "}
                        </span> for{" "}
                        <span className="highlight">
                          {" "}
                          the best car key duplication service
                        </span>
                        . Therefore, no matter how complicated your car key
                        might look, we will ensure that you get an extra copy.
                      </p>{" "}
                      <br />
                      <p>
                        <strong>Car Lock Repair</strong> <br />
                        <br />
                        Car locks can get jammed or require regular servicing.
                        Whenever you need a locksmith to repair the locks on
                        your car door, think of{" "}
                        <span className="branding">
                          {" "}
                          Pick A Lock LLC{" "}
                        </span> .{" "}
                        <span className="highlight">
                          We have the required technical know-how and equipment{" "}
                        </span>{" "}
                        to get you back on track.
                      </p>
                      <br />
                      <p>
                        <strong>Jammed Ignition Repair</strong>
                        <br /> <br />
                        Sometimes, the ignition system gets jammed as well which
                        means that your car will not start. Whenever this
                        happens, you will need someone to rescue you in the
                        soonest time possible.{" "}
                        <span className="branding"> Pick A Lock LLC </span> will
                        offer you the{" "}
                        <span className="highlight">
                          best service when it comes to repairing your ignition
                          system
                        </span>
                        . And we’ll do it with a smile because a bad attitude
                        never helped anybody! Therefore, contact us anytime for
                        excellent service with a smile.
                      </p>
                      <br />
                      <p>
                        <strong>Car Lockout Solutions</strong> <br /> <br />A
                        lockout issue is the most frustrating experience you can
                        have. However, with{" "}
                        <span className="branding"> Pick A Lock LLC </span>{" "}
                        ready to assist you,{" "}
                        <span className="highlight">
                          you’ll never have to panic because we ensure that you
                          won’t stay stuck at any time of the day or night
                        </span>{" "}
                        .
                      </p>
                      <br />
                      <p>
                        <strong>
                          Our Around-the-Clock Automotive Services:
                        </strong>
                        <br /> Lock Repair{" "}
                        <span className="highlight">
                          24-Hour Lockout Service
                        </span>{" "}
                        <br />
                        Broken Ignition Repair
                      </p>
                    </ul>
                  </div>
                </Modal.Body>
              </Modal>
            </Card.Body>
          </Col>
          <Col className="d-none d-md-inline-block">
            <Card.Img variant="top" src={props.content.img} />
          </Col>
        </Row>
      </Card>
    </>
  );
}
